import React from "react";
import MentalHealthCard from '../what/whatpage-card'
import i18n from "i18n-js";
const Indiv = () => {
  return (
    <section id="mhh-card-bg-img">
      <MentalHealthCard mhtext1={i18n.t("individuals-text1")}  classred="white-card" heading={i18n.t("individuals-heading")} textclass="text-red-trapezoid"/>
    </section>
  );
};
export default Indiv;