import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import Individualsapproach from  "../../components/approach/approach-individuals"
import ComingSoon from "../Work/Coming-soon";
const Individuals = () => {
  return (
    <Layout pageTitle="Individuals">
      <HeaderOne />
      <StickyHeader />
      <section className="mhh-bg-image">
      <Individualsapproach/>
      <ComingSoon />
      </section>
      <Footer />
    </Layout>
  );
};
export default Individuals